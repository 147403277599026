import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import logo from '../assets/images/partner-logo.png'

export default function Partners() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <section className="container mt-5 mb-5">
        {/* <h1 className="title-main text-center mb-4">SPONSORS & PARTNERS</h1>
        <div style={{ position: "relative" }} className="mt-5">
        <Carousel responsive={responsive} autoPlay>
          <div><div className="partners-logo"><img src={logo}/></div></div>
          <div><div className="partners-logo"><img src={logo}/></div></div>
          <div><div className="partners-logo"><img src={logo}/></div></div>
          <div><div className="partners-logo"><img src={logo}/></div></div>
          <div><div className="partners-logo"><img src={logo}/></div></div>
          <div><div className="partners-logo"><img src={logo}/></div></div>
          <div><div className="partners-logo"><img src={logo}/></div></div>
          <div><div className="partners-logo"><img src={logo}/></div></div>
          <div><div className="partners-logo"><img src={logo}/></div></div>
        </Carousel>
      </div> */}
    </section>
  );
}
