import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button, Dropdown, SplitButton, InputGroup } from "react-bootstrap";
import logo from '../../assets/images/logo.png'
import ticketBg from '../../assets/images/ticket-bg.png'
import './voucher.css';

const Voucher = () => {
    function printWithSpecialFileName(){
        var tempTitle = document.title;
        document.title = "ticket.pdf"
        window.print();
        document.title = tempTitle;
    }
    return (
        <>
        <div className="text-center my-3">
            <Button className="print-btn" onClick={()=>printWithSpecialFileName()}>Print E-ticket</Button>
        </div>
        <div className="ticket-details-pdf" id="divToPrint">
            <div className="ticket-container m-auto" style={{backgroundImage: ticketBg}}>
                <div className="ticket-inner-container">
                    <div className="ticket-inner">
                        <div className="ticket-logo">
                            <img className="logo" src={logo} />
                        </div>
                        <div>
                            <br></br>
                            <p className="mb-1">Dear <b>Rayyan</b>,</p>
                            <p>Your payment was successful!</p>
                            <br></br>
                            {/* <p><b>Order ID:</b> FORM_6576d87df25b2398eb03b67a_QqCR8</p> */}
                            <p><b>Name:</b> Rayyan</p>
                            <p><b>Mobile:</b> 9591461984</p>
                            <p><b>Email:</b> Rayyan@gmail.com</p>
                            <p><b>Amount:</b> 10000</p>
                            <p><b>Payment Status:</b> TXN_SUCCESS</p>
                            <p><b>Payment Date:</b> 2023-12-11 15:08:06.0</p>
                        </div>
                    </div>

                    <div className="ticket-overflow">
                        <div>
                            <p className="mb-0"><b>Thank you for signing up to the XL Bangalore Runathon</b></p>
                            <p>Looking forward to seeing you on the 4th of Feb, 2024</p>
                        </div>
                    </div>

                    <div className="ticket-inner">
                        <div>
                            <p className="mb-1">Thanks,</p>
                            <p>XLRI Jamshedpur</p>
                            <p>Phone Number: +91 657 665 3333</p>
                        </div>
                    </div>

                </div>
                
            </div>
        </div>
    </>
    )
};

export default Voucher;