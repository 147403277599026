import React from "react";
import MainBanner from "../components/MainBanner";

const RulesAndRegulations = () => {
  return (
    <div className="main-wrapper">
      <section>
        <MainBanner />
      </section>
      <section className="container my-4">
        <h1 className="title-main text-center pt-4">Rules and Regulations</h1>
        <div className="refund-section py-4 px-xs-1">
          <p>
            <span>
              Welcome to XL Runathon 2024 &ndash; run for the greater good!
              Before you dive into the excitement, take a moment to familiarize
              yourself with our rules and regulations:
            </span>
          </p>
          <p>
            <span>
              <b>General Guidelines:</b>
            </span>
          </p>
          <ul>
            <li>
              <p>
                <span>
                  Official Application Modes: Stick to the application modes
                  provided on our website for submitting your entries.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Respectful Conduct: We&apos;re all in this together! Abusive
                  or rude behaviour towards organizers, volunteers, vendors, or
                  third-party contractors will not be tolerated and may result
                  in participant bans.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Non-Transferable Registration: Your BIB is your own &ndash;
                  use only the one assigned to you.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Valid Identification: Collect your race BIB at the specified
                  location, date, and time. Bring a&nbsp;
                </span>
                <em>
                  <span>valid photo ID</span>
                </em>
                <span>&nbsp;and&nbsp;</span>
                <em>
                  <span>SMS/Email verificatio</span>
                </em>
                <span>n for collection.</span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  BIB Collection for Others: Collecting BIBs for friends? Ensure
                  you have a consent letter and photo ID from the registered
                  participant.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  BIB Exchange Prohibited: Each BIB is unique; swapping with
                  others is not allowed. Participants caught wearing someone
                  else&apos;s BIB will be disqualified.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Proper BIB Placement: Wear your BIB on your chest for
                  visibility. Avoid tampering and folding for accurate
                  measurements.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Refund Clause: Familiarize yourself with the event&apos;s
                  refund policy, including conditions and any associated fees.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Photo Release: Brace for the spotlight! Photos taken during
                  the event may be used for promotional purposes.
                </span>
              </p>
            </li>
          </ul>
          <p>
            <span>
              <b>Plan Ahead:</b>
            </span>
          </p>
          <ul>
            <li>
              <p>
                <span>
                  Health Caution: Your well-being matters! Run participation
                  without proper training or with illness/injury can be risky.
                  Prioritize your health. Please do not forget to warm up.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Minimal Baggage: For security reasons, travel light. Avoid
                  bringing valuables; we&apos;re not responsible for any loss.
                  We do not have any storage space.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Footwear and Apparel: Wear appropriate running shoes and
                  comfortable, moisture-wicking apparel suited for long-distance
                  running. Break in your shoes before the event.
                </span>
              </p>
            </li>
          </ul>
          <p>
            <span>
              <b>Participation Rules:</b>
            </span>
          </p>
          <ul>
            <li>
              <p>
                <span>
                  Individual Entries Only: We accept only individual entries for
                  the 10K, 5K, and 2K &nbsp;categories.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Age Requirement: Check and adhere to the minimum age
                  requirements for each race category.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Personal Information: Provide accurate personal details,
                  emergency contacts, and relevant medical information during
                  registration.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Race Bib Visibility: Ensure your race bib is prominently
                  displayed on your torso on the front of your t-shirt during
                  the entire race for accurate identification.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Course Adherence: Follow the designated race course without
                  taking shortcuts. Deviating from the route will lead to
                  disqualification.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Start Time Adherence: Start the race at the designated time
                  for your category. Late starts may result in inaccuracies.
                </span>
              </p>
            </li>
            <li>
              <strong>
                <p>
                  <span>
                    Course Cut-off Times: Be aware of and adhere to any course
                    cut-off times. Participants exceeding these times will be
                    cleared from the track depending on the law and order
                    permissions obtained.
                  </span>
                </p>
              </strong>
            </li>
            <li>
              <p>
                <span>
                  Sportsmanship: Maintain good sportsmanship throughout the
                  event. Respect fellow participants, volunteers, and event
                  staff.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Trash Disposal: Dispose of trash in designated bins along the
                  course.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Hydration Stations: Use designated hydration stations for
                  water and refreshments. Avoid impeding the flow of other
                  runners.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Race Officials&apos; Instructions: Follow instructions from
                  race officials, marshals, and volunteers for a safe and
                  organized event.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Timing Mats: Cross all timing mats along the course to ensure
                  an accurate recording of your race time.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Medical Assistance: Seek medical assistance if needed. Do not
                  continue the race if you are injured or unwell.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Personal Audio Devices: If required, use personal audio
                  devices responsibly, ensuring awareness of your surroundings.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Finish Line Protocol: Follow finish line protocols, including
                  collecting your finisher&apos;s medal and any other post-race
                  procedures.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Post-Race Gathering Area: Use the designated post-race
                  gathering area to reunite with family and friends. Avoid
                  lingering in high-traffic areas.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Event Feedback: Provide constructive feedback to the
                  organizers after the race to help enhance future events.
                </span>
              </p>
            </li>
          </ul>
          <p>
            <span>
              <b>Timing Regulations:</b>
            </span>
          </p>
          <ul>
            <li>
              <p>
                <span>
                  BIB Exchange Prohibited: Each timing BIB is unique; swapping
                  is not allowed. Disqualification awaits those wearing
                  another&apos;s BIB.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Results Publication: Official 10K and 5K race results will be
                  posted on the event website within 48 hours after official
                  verification. Check the website for your recorded time.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  E-Certificates: Timed or not, all participants receive
                  E-Certificates for event completion.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Finisher&apos;s Medal: All 10K, 5K, and 2K Run participants
                  will receive a Finisher&apos;s Medal post-run.
                </span>
              </p>
            </li>
          </ul>
          <p>
            <span>
              <b>Prize Money</b>
            </span>
          </p>
          <ul>
            <li>
              <p>
                <span>Structure</span>
              </p>
            </li>
          </ul>
          <ul>
            <ul>
              <li>
                <p>
                  <span>
                    The race and age categories apply to men and women
                    participants and are subject to race rules and regulations.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Race Category - Overall Fastest Men | Overall Fastest Women
                    both in 10K &amp; 5K.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Age Category - 18 years to 22 years | 23 years to 49 years |
                    50+ years both men and women in 10K and 5K.
                  </span>
                </p>
              </li>
            </ul>
          </ul>

          <ul>
            <li>
              <p>
                <span>Rules</span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      Prize money will be distributed to winners based on their
                      official finishing times and placements in their
                      respective race categories.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                    <b>Finisher Medal Cut-off Time: to be eligible for the finisher medal, participants in all categories must complete the race by 8:30 am.</b>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                    Prize Qualification Cut-off Times: to qualify for prizes, participants in the 5K category must finish within 40 minutes, while those in the 10K category must finish within 60 minutes.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                    Race Start Time Window: participants must commence the race within 20 minutes of their designated start time to be considered for prize eligibility.
                    </span>
                  </p>
                </li>

                <li>
                  <p>
                    <span>
                    10K Race Start Guidelines: for the 10K race starting at 6:00 am, participants must begin no later than 6:20 am to remain eligible for prizes.
                    </span>
                  </p>
                </li>

                <li>
                  <p>
                    <span>
                    5K Race Start Guidelines: participants in the 5K race, commencing at 6:15 am, should initiate the race by 6:35 am at the latest to maintain eligibility for prizes.
                    </span>
                  </p>
                </li>

                <li>
                  <p>
                    <span>
                      Participants who are disqualified for rule violations
                      during or after the race may forfeit their eligibility for
                      prize money.
                    </span>
                  </p>
                </li>

                <li>
                  <p>
                    <span>
                      To qualify for prizes, participants must ensure that their
                      Bib tag timing is successfully captured at all designated
                      timing points along the course, including both the start
                      and finish mats. Incomplete or missing timing data at any
                      point may result in disqualification from prize
                      eligibility.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Participants achieving the title of the overall fastest
                      runner will not be eligible for additional age category
                      prizes. In the event of a participant securing both the
                      fastest overall time and an age category win, only the
                      highest-value prize will be awarded to ensure fair
                      distribution and equal opportunities across prize
                      categories.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      The 2K run will not be timed or be eligible for prize
                      money.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      The race director/organizer&rsquo;s decision is final.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      All prize winners will be evaluated based on chip time.
                    </span>
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default RulesAndRegulations;