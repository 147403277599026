import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button, Dropdown, SplitButton, InputGroup } from "react-bootstrap";
import MainBanner from "../../components/MainBanner";
import { useNavigate } from "react-router-dom";

const ManageBookings = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState("");
    const [phone, setPhone] = useState("");
    const [errors, setErrors] = useState({value: null, phone: null});
    const [runCitiesDates, setRunCitiesDates] = useState([]);

    useEffect(() => {
        getRunCitiesDates();
    }, [])

    const citiesList = [
        { name: "Bengaluru", value: "FORM-DYN-2023-74M9SV"},
        { name: "Chennai", value: "FORM-DYN-2023-JJOHBI"},
        { name: "Delhi", value: "FORM-DYN-2023-CYF1QB"},
        { name: "Jamshedpur", value: "FORM-DYN-2023-S4B90X"},
        { name: "Kolkata", value: "FORM-DYN-2023-8ZP520"},
        { name: "Mumbai", value: "FORM-DYN-2023-0WDO05"},
        { name: "Hyderabad", value: "FORM-DYN-2023-ST9SGQ"},
        { name: "Pune", value: "FORM-DYN-2023-USACX0"},
    ];

    const handlePickNumeric = (e) => {
        setErrors({ ...errors, phone: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setPhone(onlyNums);
        } else if (onlyNums.length === 10) {
            setPhone(onlyNums);
        }
    }
    const getRunCitiesDates = () => {
        axios
            .get("https://www.itwgo.in/xlri/pages/manage/")
            .then(res => {
                if (res.status) {
                    setRunCitiesDates(res.data);
                }
            })
            .catch(error => setLoading(false));
    };

    const checkValidation = () => {
        let valErr = value === "" ? "Please select city" : null;
        let phoneErr = phone === "" ? "Please enter phone number" : phone.length < 10 ? "Please enter valid phone number" : null;

        if(valErr || phoneErr){
            setErrors({...errors, value: valErr, phone: phoneErr});
            return;
        }
        else{
            handleBooking();
        }
    }

    const handleBooking = () => {
        
          const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6InRlc3R1c2VyIn0.LFTkw2WzDoMTBcmubWvqaUfOv-rRe94jqC5xelYMLuQ' 
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
          setLoading(true); 
        axios
            .post(`https://web-api.xlri.edu/xlri-utility/response/${value}/${phone}`, {}, {
                headers: headers
              })
            .then(res => {
                if (res?.data?.length > 0) {
                   
                    // console.log(runCitiesDates[value] )
                    // setCountryList(res.data.data.data)

                    setLoading(false);

                     navigate("/bookings",{ state: { data: {...res.data[0],...runCitiesDates[value]} } });
                }
                else{
                    alert("No booking found!!")
                }
            })
            .catch(error => {setLoading(false);alert("Try after sometime!!")});
    };

    return (
        <>
        <MainBanner />
        <section className="container my-4">
            <h1 className="title-main text-center mt-5">MANAGE BOOKING</h1>
            <Form className="participant-details">
                <Row className="">
                    <Col xs={12} md={6} className="m-auto">
                        <Row>
                            <Col xs={12} md={12}>
                                <Form.Label>Run</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    value={value}
                                    onChange={(e) => {setValue(e.target.value); setErrors({...errors, value: null})}}
                                >
                                    <option value={"Select Run"}>Select city</option>
                                    {citiesList.map((item, index) =>
                                        <option value={item?.value}>{item?.name}</option>
                                    )}
                                </Form.Select>
                                {errors.value !== null && (<Form.Text style={{ color: "red" }}>{errors.value}</Form.Text>)}
                            </Col>
                            
                            <Col xs={12} md={12} className="mt-3">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your phone number"
                                    value={phone}
                                    onChange={handlePickNumeric}
                                />
                                {errors.phone !== null && (<Form.Text style={{ color: "red" }}>{errors.phone}</Form.Text>)}
                            </Col>
                            
                            
                            <Col xs={12} md={12} className="text-center">
                                <Button className="btn btn-primary btn-confirm"
                                    disabled={loading}
                                    onClick={!loading?checkValidation:null}>Confirm</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </section>
    </>
    )
};

export default ManageBookings;