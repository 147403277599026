import React, {useLayoutEffect} from "react";
import {  Routes, Route, useLocation } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Register from "./pages/Register";
import About from "./pages/About";
import TermsAndConditions from "./pages/TermsAndConditions";
import RulesAndRegulations from "./pages/RulesAndRegulations";
import PrizeMoney from "./pages/PrizeMoney";
import ManageBookings from "./pages/booking/ManageBookings";
import BookingLists from "./pages/booking/BookingLists";
import Voucher from "./pages/booking/Voucher";
import Results from "./pages/Results";


function App() {
  const location = useLocation()
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  
  return (
    <div>
        <Routes>
          <Route path="/" element={<Header />}>
            <Route index path="/" element={<Home />} />
            <Route index path="/register" element={<Register />} />
            <Route index path="/register-bengaluru" element={<Register />} />
            <Route index path="/register-delhi" element={<Register />} />
            <Route index path="/register-chennai" element={<Register />} />
            <Route index path="/register-jamshedpur" element={<Register />} />
            <Route index path="/register-kolkata" element={<Register />} />
            <Route index path="/register-mumbai" element={<Register />} />
            <Route index path="/register-hyderabad" element={<Register />} />
            <Route index path="/register-pune" element={<Register />} />
            <Route index path="/about" element={<About />} />
            <Route index path="/termsandconditions" element={<TermsAndConditions />} />
            <Route index path="/rules" element={<RulesAndRegulations />} />
            <Route index path="/prize-money" element={<PrizeMoney />} />
            <Route index path="/results" element={<Results />} />
            <Route index path="/manage-booking" element={<ManageBookings />} />
            <Route index path="/bookings" element={<BookingLists />} />
            <Route index path="/e-ticket" element={<Voucher />} />
          </Route>
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
