
import { Outlet, Link, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/images/logo.png'
import logoRight from '../assets/images/logo-right.png'
import centrum from '../assets/images/centrum.png'
import './header.css'
import { Button } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
  const handleClickScroll = () => {
    const currentURL = window.location.href;
    var url = currentURL.split("/")[currentURL.split("/").length - 1]
    
    if (url !== ""){
      window.location.href = '/#team_section';
    }
    else{
      let element = document.getElementById('team_section');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
   
  };

  return (
    <>
      <header>
        <Navbar collapseOnSelect expand="lg" className="custon-navbar container">
          
            <Nav className="nav-left">
                <Navbar.Brand><Link to="/"><img className="logo" src={logo} /></Link></Navbar.Brand>
                <div className="header-text"><img className="logo-right" src={logoRight} /></div>
                <div className="sponsor-logo">
                    <p>Title Sponsor</p>
                    <img className="logo-right" src={centrum} />
                </div>
            </Nav>
            
            <Nav className="nav-right">
                {/* <Link to="/"><Button className="btn btn-primary">Log In</Button></Link> */}
                <Link to="/about" className="btn-cart"><span >About XLRI</span></Link>
                <NavDropdown title="Race Results" id="basic-nav-dropdown">
                  <NavDropdown.Item href="https://app.racetime.in/results/list?race=centrum-xlrunathon-bangalore-2024" target="_blank">Bangalore</NavDropdown.Item>
                  <NavDropdown.Item href="https://app.racetime.in/results/list?race=centrum-xlrunathon-chennai-2024" target="_blank">Chennai</NavDropdown.Item>
                  <NavDropdown.Item href="https://app.racetime.in/results/list?race=centrum-xlrunathon-kolkata-2024" target="_blank">Kolkata</NavDropdown.Item>
                  <NavDropdown.Item href="https://app.racetime.in/results/list?race=centrum-xlrunathon-jamshedpur-2024" target="_blank">Jamshedpur</NavDropdown.Item>
                  <NavDropdown.Item href="https://app.racetime.in/results/list?race=centrum-xlrunathon-mumbai-2024" target="_blank">Mumbai</NavDropdown.Item>
                  <NavDropdown.Item href="https://app.racetime.in/results/list?race=centrum-xlrunathon-hyderabad-2024" target="_blank">Hyderabad</NavDropdown.Item>
                  <NavDropdown.Item href="https://app.racetime.in/results/list?race=centrum-xl-runathon-pune-2024" target="_blank">Pune</NavDropdown.Item>
                </NavDropdown>
                <Link to="/manage-booking" className="btn-cart "><span >Manage Booking</span></Link>
                <Link to="/prize-money" className="btn-cart "><span >Prize Money</span></Link>
                <Link to="/results" className="btn-cart "><span >Winners</span></Link>
                <Link to="/rules" className="btn-cart"><span >Rules & Regulations</span></Link>
                {/* <Link to="/" className="btn-cart"><span ><i class="fa fa-shopping-cart" aria-hidden="true"></i> Cart</span></Link> */}
            </Nav>
        </Navbar>
      </header>
      <Outlet />
    </>
  )
};

export default Header;