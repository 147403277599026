import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button, Dropdown, SplitButton, InputGroup } from "react-bootstrap";
import MainBanner from "../../components/MainBanner";
import './voucher.css';
import logo from '../../assets/images/logo.png'
import logoRight from '../../assets/images/logo-right.png'
import ticketBg from '../../assets/images/ticket-bg.png'
import {useLocation} from 'react-router-dom';

const BookingLists = () => {
    const location = useLocation();
    let data = location?.state?.data;
    const citiesList = [
        { name: "Bengaluru", value: "FORM-DYN-2023-74M9SV"},
        { name: "Chennai", value: "FORM-DYN-2023-JJOHBI"},
        { name: "Delhi", value: "FORM-DYN-2023-CYF1QB"},
        { name: "Jamshedpur", value: "FORM-DYN-2023-S4B90X"},
        { name: "Kolkata", value: "FORM-DYN-2023-8ZP520"},
        { name: "Mumbai", value: "FORM-DYN-2023-0WDO05"},
        { name: "Hyderabad", value: "FORM-DYN-2023-ST9SGQ"},
        { name: "Pune", value: "FORM-DYN-2023-USACX0"},
    ];
    function printWithSpecialFileName(){
        var tempTitle = document.title;
        document.title = "XLRI_marathon_ticket.pdf"
        window.print();
        document.title = tempTitle;
    }
    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${mo} ${da}, ${ye}`
        return newDate;
    }

    return (
        <>
        <div className="hidePrint">
            <MainBanner />
            <section className="container bookings my-4">
                <h1 className="title-main text-center mt-5">BOOKING LIST</h1>
                <Form className="participant-details">
                    <Row className="mb-3">
                        <Col xs={12} md={10} className="m-auto">
                            <div className="card p-3">
                                <div className="booking-list-card mb-3">
                                    <div className="list-card">
                                        <div className="list-title-1"><b>Name:</b></div>
                                        <div className="list-title-2" style={{textTransform: "capitalize"}}>{data?.Name}</div>
                                    </div>
                                    <div className="list-card">
                                        <div className="list-title-1"><b>City:</b></div>
                                        <div className="list-title-2">{data?.city}</div>
                                    </div>
                                    <div className="list-card">
                                        <div className="list-title-1"><b>Run Date:</b></div>
                                        <div className="list-title-2">{data?.date}</div>
                                    </div>
                                    <div className="list-card">
                                        <div className="list-title-1"><b>Run Category:</b></div>
                                        <div className="list-title-2">{data?.["Select the run"]}</div>
                                    </div>
                                    <div className="list-card">
                                        <div className="list-title-1"><b>T-Shirt Size:</b></div>
                                        <div className="list-title-2">{data?.["T-Shirt Size"]}</div>
                                    </div>
                                </div>
                                <div className="booking-list-card ">
                                    <div className="list-card">
                                        <div className="list-title-1"><b>Mobile:</b></div>
                                        <div className="list-title-2">{data?.["Phone Number"]}</div>
                                    </div>
                                    <div className="list-card">
                                        <div className="list-title-1"><b>Email:</b></div>
                                        <div className="list-title-2">{data?.Email}</div>
                                    </div>
                                    <div className="list-card">
                                        <div className="list-title-1"><b>Amount:</b></div>
                                        <div className="list-title-2">{data?.amount} INR</div>
                                    </div>
                                    <div className="list-card">
                                        <div className="list-title-1"><b>Payment Status:</b></div>
                                        <div className="list-title-2">{data?.paymentStatus ? "Success" : "Failed"}</div>
                                    </div>
                                        <div className="list-card">
                                        <div className="list-title-1"><b>Payment Date:</b></div>
                                        {data?.paymentDate && (
                                            <div className="list-title-2">{formatDate(data?.paymentDate)}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="text-end mt-3">
                                    <Button className="btn btn-primart text-lowecase" onClick={()=>printWithSpecialFileName()}>Print E-ticket</Button>
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
                    
                </Form>
            </section>
        </div>

        <div className="ticket-details-pdf" id="divToPrint">
            <div className="ticket-container m-auto" style={{backgroundImage: logo}}>
                <div className="ticket-inner-container">
                    <div className="ticket-inner">
                        <div className="ticket-logo">
                            <img className="logo" src={logo} />
                            <img className="logoRight" src={logoRight} />
                        </div>
                        <div>
                            <br></br>
                            <br></br>
                            <p className="mb-1" style={{textTransform: "capitalize"}}>Dear <b>{data?.Name}</b>,</p>
                            <br></br>
                            <p>Your payment is {data?.paymentStatus ? "successful" : "failed"}!</p>
                            <br></br>
                            {/* <p><b>Order ID:</b> FORM_6576d87df25b2398eb03b67a_QqCR8</p> */}
                            <p style={{textTransform: "capitalize"}}><b>Name:</b> {data?.Name}</p>
                            <p><b>City:</b> {data?.city}</p>
                            <p><b>Run Date:</b> {data?.date}</p>
                            <p><b>Run Category:</b> {data?.['Select the run']}</p>
                            <p><b>T-shirt Size:</b> {data?.['T-Shirt Size']}</p>
                            <p><b>Mobile:</b> {data?.["Phone Number"]}</p>
                            <p><b>Email:</b> {data?.Email}</p>
                            <p><b>Amount:</b> {data?.amount}</p>
                            <p><b>Payment Status:</b> {data?.paymentStatus ? "Success" : "Failed"}</p>
                            {data?.paymentDate && (
                                <p><b>Payment Date:</b> {formatDate(data?.paymentDate)}</p>
                                )}
                                <br></br>
                                <p><b>BIB's Collection Date:</b> {data?.bib_date}</p>
                                <br></br>
                        </div>
                    </div>

                    <div className="ticket-overflow">
                        <div>
                            <p className="mb-0"><b>Thank you for signing up to the XL {data?.city} Runathon.</b></p>
                            <p>Looking forward to seeing you on {data?.date}</p>
                        </div>
                    </div>

                    <div className="ticket-inner refund-pol">
                            <p className="mb-1">Thanks,</p>
                            <p><b>CENTRUM XL Runathon 2024 - Run for the greater good.</b></p>
                            <p><i>An initiative by XLRI - Xavier's School of Management - Jamshedpur.</i></p>
                            <a href="https://wa.me/919611330534" className='text-decoration-none' target="_blank"><p>Click here to reach us on WhatsApp</p></a>
                    </div>

                    <section className="ticket-tnc pt-4">
                        <h1 className="title-main text-start">Refund Policy</h1>
                        <div className="refund-section">
                            <p className="text-start">
                                We appreciate your interest in participating in our runathon event.
                                Please be aware that we have a strict no refund policy in place.
                                Once you register for the event and complete your payment, your
                                entry is considered final and non-refundable. This policy is in
                                effect regardless of the circumstances, including but not limited to
                                injury, scheduling conflicts, or personal reasons that prevent your
                                participation. We commit substantial resources to planning and
                                organizing the event, and your registration fees directly support
                                these efforts. In the event of unforeseen circumstances, such as
                                event cancellation due to extreme weather or other factors beyond
                                our control, we will make every effort to reschedule the event or
                                offer alternative options for registered participants. Your
                                understanding and support in this matter are greatly appreciated, as
                                they help us ensure the success and sustainability of our runathon
                                event. If you have any questions or concerns, please feel free to
                                contact our event organizers for further information or
                                clarifications. Thank you for your cooperation.
                            </p>
                        </div>
                        <h1 className="title-main text-start pt-4">Terms and Conditions</h1>
                        <div className="refund-section">
                        <p>
                            <span>A.&nbsp;</span>
                            <span>
                            <u>DEFINITIONS</u>
                            </span>
                        </p>
                        <p>
                            <span>(I)</span>
                            <span>&quot;EVENT&quot; means&nbsp;</span>
                            <span>XLRI RUNATHON</span>
                            <span>&nbsp;(also known as&nbsp;</span>
                            <span>XLRUNATHON 2024</span>
                            <span>&nbsp;) being an initiative of&nbsp;</span>
                            <span>XLRI - SCHOOL OF MANAGEMENT, JAMSHEDPUR.</span>
                        </p>
                        <p>
                            <span>(II)</span>
                            <span>
                            &ldquo;PARTICIPANT&rdquo; means any person (including a child)
                            registering (or being registered) for the EVENT.
                            </span>
                        </p>
                        <p>
                            <span>(III)</span>
                            <span>&ldquo;EVENT ORGANIZER&rdquo; means&nbsp;</span>
                            <span>XLRI - SCHOOL OF MANAGEMENT, JAMSHEDPUR</span>
                            <span>
                            &nbsp;and to include its affiliates and associates and its/their
                            successors or assigns but not limited to the aforestated persons
                            and entities.
                            </span>
                        </p>
                        <p>
                            <span>(IV)</span>
                            <span>
                            &ldquo;MERCHANDISE&rdquo; means any item or items (including
                            packages) purchased by any PARTICIPANT through the EVENT website /
                            online facility.
                            </span>
                        </p>
                        <p>
                            <span>(V)</span>
                            <span>
                            &ldquo;NGO&rdquo; means any non-government organization or Social
                            Worker working in support of a social cause or in providing
                            support to other NGOs and affiliated or associated with the EVENT.
                            </span>
                        </p>
                        <p>
                            <span>(VI)</span>
                            <span>
                            &ldquo;REGISTRATION FEES/DONATION&rdquo; means the sum of INR 700
                            or any other specified amount payable and/or paid by the
                            PARTICIPANT for participating in the EVENT.
                            </span>
                        </p>
                        <p>
                            <span>B.&nbsp;</span>
                            <span>
                            <u>
                                TERMS &amp; CONDITIONS, ACCEPTANCE OF WAIVER &amp; RELEASE OF
                                LIABILITY
                            </u>
                            </span>
                        </p>
                        <p>
                            <span>1.</span>
                            <span>
                            &nbsp;PARTICIPANT confirms registering for participation in the
                            EVENT on a purely voluntary basis.
                            </span>
                        </p>
                        <p>
                            <span>2.</span>
                            <span>
                            &nbsp;PARTICIPANT taking part in the EVENT is solely responsible
                            for selecting a safe route to run and recognizes that
                            participation in the EVENT &nbsp;is a potentially hazardous
                            activity and willingly assumes all risks associated with such
                            participation, including, but not limited to, falls; contact with
                            other participants, spectators or others, or vehicular or other
                            traffic; the effects of the weather, including heat and/or
                            humidity, wind, cold temperature, and wet or slippery surfaces;
                            falling tree branches or other overhead objects; traffic; and the
                            crowded nature and other conditions of the course chosen by the
                            PARTICIPANT, all such risks being known and appreciated by the
                            PARTICIPANT.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>3.&nbsp;</span>
                            <span>
                            PARTICIPANT confirms to follow all precautions required of the
                            PARTICIPANT in this event including but not limited to wearing of
                            mask in public spaces or the outdoors, social distancing and any
                            other guidelines or directives issued by the event manager, the
                            local authorities, state authorities or central authorities.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>4.&nbsp;</span>
                            <span>
                            For any child (age less than 18 years as on date of the event)
                            registered by a PARTICIPANT for the event, the PARTICIPANT hereby
                            accepts responsibility for the child for all clauses and points
                            herein in relation to the child.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>5.&nbsp;</span>
                            <span>
                            By agreeing to these Terms &amp; Conditions, consent of the
                            Parent/Guardian for the participation of their child/children in
                            the Event is assumed.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>6.&nbsp;</span>
                            <span>
                            PARTICIPANT submitting the application for registration for a
                            child/children along with her/his/their own, acknowledges that
                            running / long-distance running is an extreme sport and may injure
                            the body and/or health. PARTICIPANTS registering for children take
                            full responsibility for the child/children participating in the
                            EVENT and shall not hold the EVENT MANAGER or organizing committee
                            or any of its members or entities responsible for any injury or
                            accident or harm.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>7.&nbsp;</span>
                            <span>
                            Children must be above the age of 10 years as on the date of the
                            EVENT to participate in the `Run with children&rsquo;
                            category.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>8.&nbsp;</span>
                            <span>
                            PARTICIPANT must be above the age of 14 years as on the date of
                            the event to participate in the `10 km Run&rsquo; category.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>9.&nbsp;</span>
                            <span>
                            PARTICIPANT confirms that the PARTICIPANT&rsquo;s name,
                            photographs, and video of their participation and the
                            child/children registered by the PARTICIPANT can be used by the
                            EVENT MANAGER to publicize the Event.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>10.&nbsp;</span>
                            <span>
                            PARTICIPANT acknowledges and agrees that any personal information
                            of the PARTICIPANT can be stored and used by organizers and/or any
                            of its affiliates and/or the NGO supported by the PARTICIPANT for
                            any purpose including for the compilation of statistical
                            information.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>11.&nbsp;</span>
                            <span>
                            PARTICIPANT acknowledges that the RUNNER REGISTRATION
                            FEES/DONATIONS are for participation in the event and are not, in
                            full or in part, a donation or contribution to the NGO supported
                            by the participant.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>12.&nbsp;</span>
                            <span>
                            EVENT MANAGER shall be entitled to suspend, or close registrations
                            without prior notice.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>13.&nbsp;</span>
                            <span>
                            Registration must be completed through the online registration
                            platform available in the official website, or by any alternative
                            mode indicated by the organizing committee.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>14.&nbsp;</span>
                            <span>
                            Confirmed registrations are non-transferable and cannot be
                            modified except as provided.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>15.&nbsp;</span>
                            <span>
                            The EVENT MANAGER will not be held responsible for any
                            cancellation or rescheduling of the event for reasons beyond their
                            control.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>16.&nbsp;</span>
                            <span>
                            Digital representation of MERCHANDISE is for information only and
                            is not a guarantee of actual specifications and appearance of the
                            MERCHANDISE.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>17.&nbsp;</span>
                            <span>
                            Size of MERCHANDISE will be as per generally prevalent or assumed
                            norms or practices and the EVENT MANAGER does not guarantee
                            fitment for any specific individual or PARTICIPANT.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>18.&nbsp;</span>
                            <span>
                            Any MERCHANDISE purchased by the PARTICIPANT is on a
                            non-exchangeable and non-returnable and non-refundable
                            basis.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>19.&nbsp;</span>
                            <span>
                            MERCHANDISE may be or shall be distributed by the EVENT MANAGER
                            and/or designated Vendor of the EVENT MANAGER.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>20.&nbsp;</span>
                            <span>
                            PARTICIPANT acknowledges that the amount paid towards purchase of
                            MERCHANDISE related to the EVENT are not, in full or in part, a
                            donation or contribution to the NGO supported by the
                            participant.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span>22.</span>
                            <span>
                            &nbsp;Having read these Terms &amp; Conditions and Waiver and
                            Assumption of Risk knowing these facts, and in consideration of
                            &nbsp;acceptance of the PARTICIPANT&rsquo;s application for the
                            EVENT, the PARTCIPANT, waives and releases the EVENT MANAGER and
                            all sponsors and officials of the EVENT, and the employees,
                            volunteers, and other representatives, agents, and successors of
                            each of the foregoing (hereinafter referred to as the
                            &ldquo;RELEASEES&rdquo;), from present and future claims and
                            liabilities of any kind, known or unknown, arising out of the
                            PARTICIPANT&rsquo;s participation in the Events, even though such
                            claim or liability may arise out of negligence or fault on the
                            part of any of the RELEASEES.
                            </span>
                        </p>
                        </div>
                    </section>

                   


                </div>
                
            </div>
        </div>

    </>
    )
};

export default BookingLists;